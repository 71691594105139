import React, { Fragment } from "react";
import { Form, Col, Table } from "react-bootstrap";
import TbodyOrden from "./TbodyOrden";
import { useSelector, useDispatch } from "react-redux";
import {
  updateEntregarOrden,
  totalMontoSeleccion,
} from "actions/transportOrdersAction";
import { formatNumberCurrency } from "utils/validationInput";

const TableOrden = ({ register }) => {
  const dispatch = useDispatch();

  const { listOrdenEntregar, documentacionsPago, totalMonto } = useSelector(
    (state) => state.transportOrders
  );

  /**const handleChange = (event) => {
    if (event.target.checked) {
      dispatch(repetirSeleccion());
    }
  }; */

  const changeTotales = async (event, orden_flete_id) => {
    const param = event.target.name.split(".")[1];
    const valor = event.target.checked;

    dispatch(updateEntregarOrden(orden_flete_id, param, valor));
    dispatch(totalMontoSeleccion());
  };

  return (
    <Fragment>
      <Form.Row>
        {/**<Col xs={12} className="text-right">
          <Form.Group as={Col} controlId="repetir_documento">
            <Form.Check
              type="checkbox"
              name="repetir_documento"
              defaultValue="si"
              onChange={(event) => handleChange(event)}
              ref={register}
              label="Mismo tipo y número de documento"
              disabled={disabledBtnPago}
            />
          </Form.Group>
        </Col> */}
        <Col xs={12}>
          <Form.Group as={Col}>
            <Table bordered size="sm" responsive className="table-global mb-0">
              <thead className="bg-warn-blue">
                <tr>
                  <th>N° de Orden</th>
                  <th>Forma de Pago</th>
                  <th>Total</th>
                  <th>Documento</th>
                  <th>N° de Documento</th>
                  <th>Estado</th>
                  <th>Seleccione</th>
                </tr>
              </thead>
              <tbody>
                {listOrdenEntregar?.length > 0 ? (
                  listOrdenEntregar?.map((orden, index) => {
                    return (
                      <TbodyOrden
                        key={index}
                        index={index}
                        {...orden}
                        register={register}
                        documentacions={documentacionsPago}
                        changeTotales={changeTotales}
                      />
                    );
                  })
                ) : (
                  <tr className="tr-vacia">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                )}
              </tbody>
              <tfoot>
                <tr className="tr-vacia">
                  <td></td>
                  <td className="fw-600 text-right">Total</td>
                  <td>$ {formatNumberCurrency(totalMonto)}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tfoot>
            </Table>
          </Form.Group>
        </Col>
      </Form.Row>
    </Fragment>
  );
};

export default TableOrden;
