import { OverlayTrigger, Tooltip } from "react-bootstrap";
import descargar from "assets/images/descargar.png";
import shopping_list from "assets/images/icono1.png";
import { downloadDocumento } from "utils/downloadFile";
import { useDispatch } from "react-redux";
import {
  searchDocument,
  exportarFileOrdersExcel,
} from "actions/facturacionAction";

const Descargar = (row) => {
  const dispatch = useDispatch();

  const obtenerDocumento = async (event, row) => {
    event.preventDefault();

    if (row.url_documento.length === 0) {
      const body = {
        tipo_documento: "factura",
        numero_documento: row.numero_folio,
      };
      await dispatch(searchDocument(body));
    } else {
      let url = row.url_documento;
      /**if(row.url_documento.includes("http")){
				url = row.url_documento.replace("http", "https")
			}**/
      let data = {
        url: url,
        numero_documento: row.numero_folio,
      };

      downloadDocumento(data);
    }
  };

  const generateDocument = async (event, row) => {
    event.preventDefault();

    if (row.type === "invoice") {
      obtenerDocumento(event, row);
    } else if (row.type === "detail_invoice") {
      dispatch(exportarFileOrdersExcel(row.numero_folio));
    }
  };

  return (
    <OverlayTrigger overlay={<Tooltip id="tooltip-crear">{row.title}</Tooltip>}>
      <button
        style={{ height: "37px" }}
        className="btn btn-xs pb-0 pt-1 mb-0"
        key={row.numero_documento}
        onClick={(event) => generateDocument(event, row)}
      >
        <img
          src={row.type === "invoice" ? descargar : shopping_list}
          height="30"
          alt={row.title}
        />
      </button>
    </OverlayTrigger>
  );
};

export default Descargar;
