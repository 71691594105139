import Descargar from "../Descargar.jsx";
import { formatNumberCurrency } from "../../../../utils/validationInput";

const Ordenes = {
  columnas: [
    {
      name: "Número de Folio",
      selector: (row, i) => row.numero_folio,
      sortable: true,
    },
    {
      /** 
      name: "Fecha Orden",
      selector: (row, i) => row.fecha_orden,
      sortable: true,
    ,
    {
      name: "Fecha Entrega",
      selector: (row, i) => row.fecha_entrega,
      sortable: true,
    */
    },
    {
      name: "Fecha Factura",
      selector: (row, i) => row.fecha_factura,
      sortable: true,
    },
    {
      name: "Cant órdenes",
      selector: (row, i) => row.cant_orden,
      sortable: true,
    },
    {
      name: "Monto",
      selector: (row, i) => row.total,
      sortable: true,
      right: true,
    },
    {
      name: "",
      sortable: true,
      center: true,
      cell: (row) => (
        <Descargar title="Descargar factura" type="invoice" {...row} />
      ),
      width: "50px",
    },
    {
      name: "",
      sortable: true,
      center: true,
      cell: (row) => (
        <Descargar
          title="Descargar detalle de la factura"
          type="detail_invoice"
          {...row}
        />
      ),
      width: "50px",
    },
  ],
  withColumnas: [
    {
      name: "Número de Folio",
      selector: (row, i) => row.numero_folio,
      sortable: true,
    },
    {
      /** 
      name: "Fecha Orden",
      selector: (row, i) => row.fecha_orden,
      sortable: true,
    },
    {
      name: "Fecha Entrega",
      selector: (row, i) => row.fecha_entrega,
      sortable: true,
    */
    },
    {
      name: "Fecha Factura",
      selector: (row, i) => row.fecha_factura,
      sortable: true,
    },
    {
      name: "N° Órdenes",
      selector: (row, i) => row.numero_orden,
      sortable: true,
    },
    {
      name: "Monto",
      selector: (row, i) => formatNumberCurrency(row.total),
      sortable: true,
      right: true,
    },
  ],
};

export default Ordenes;
