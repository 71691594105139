const API_SITE_MAIN = process.env.REACT_APP_API_URL;

const endPoints = {
  role: {
    list: (typeFilter) => `${API_SITE_MAIN}/admin/roles/filter/${typeFilter}`,
    permissions: `${API_SITE_MAIN}/admin/roles/permissions`,
    add: `${API_SITE_MAIN}/admin/roles`,
    edit: (id) => `${API_SITE_MAIN}/admin/roles/${id}`,
    delete: (id) => `${API_SITE_MAIN}/admin/roles/${id}`,
    getOne: (id) => `${API_SITE_MAIN}/admin/roles/${id}`,
  },
  user: {
    list: (type_rol, type_filter) =>
      `${API_SITE_MAIN}/admin/users/filter/${type_rol}/${type_filter}`,
    delete: (id) => `${API_SITE_MAIN}/admin/users/${id}`,
    add: `${API_SITE_MAIN}/admin/users`,
    edit: (id) => `${API_SITE_MAIN}/admin/users/${id}`,
    passwordChange: (id) => `${API_SITE_MAIN}/admin/users/${id}/password`,
    assignVehicle: (id) => `${API_SITE_MAIN}/admin/users/${id}/vehicle`,
    listUserByCamionId: (camion_id) =>
      `${API_SITE_MAIN}/admin/camions/${camion_id}/users`,
    getOne: (id) => `${API_SITE_MAIN}/admin/users/${id}`,
    removeVehicle: (id) => `${API_SITE_MAIN}/admin/users/${id}/vehicle/remove`,
  },
  auth: {
    login: `${API_SITE_MAIN}/auth/login`,
    logout: `${API_SITE_MAIN}/auth/logout`,
    verifyAuthorized: `${API_SITE_MAIN}/auth/verify-authorized`,
    resetPassword: `${API_SITE_MAIN}/auth/reset-password`,
  },
  customer: {
    search: (rut) => `${API_SITE_MAIN}/admin/customers/search/${rut}`,
    typeSearch: `${API_SITE_MAIN}/customers/search`,
    list: `${API_SITE_MAIN}/admin/customers`,
    add: `${API_SITE_MAIN}/admin/customers`,
    edit: (id) => `${API_SITE_MAIN}/admin/customers/${id}`,
    delete: (id) => `${API_SITE_MAIN}/admin/customers/${id}`,
    getOne: (id) => `${API_SITE_MAIN}/admin/customers/${id}`,
    invitation: `${API_SITE_MAIN}/admin/customers/invitation`,
    invitationByEmail: `${API_SITE_MAIN}/admin/customers/invitation-web`,
  },
  correlative: {
    list: `${API_SITE_MAIN}/admin/correlatives`,
    add: `${API_SITE_MAIN}/admin/correlatives`,
    edit: (id) => `${API_SITE_MAIN}/admin/correlatives/${id}`,
    delete: (id) => `${API_SITE_MAIN}/admin/correlatives/${id}`,
    getOne: (id) => `${API_SITE_MAIN}/admin/correlatives/${id}`,
  },
  city: {
    list: (type) => `${API_SITE_MAIN}/admin/cities/filter/${type}`,
    add: `${API_SITE_MAIN}/admin/cities`,
    edit: (id) => `${API_SITE_MAIN}/admin/cities/${id}`,
    delete: (id) => `${API_SITE_MAIN}/admin/cities/${id}`,
    getOne: (id) => `${API_SITE_MAIN}/admin/cities/${id}`,
  },
  office: {
    list: `${API_SITE_MAIN}/admin/cities`,
    add: `${API_SITE_MAIN}/admin/offices`,
    edit: (id) => `${API_SITE_MAIN}/admin/offices/${id}`,
    delete: (id) => `${API_SITE_MAIN}/admin/offices/${id}`,
    getOne: (id) => `${API_SITE_MAIN}/admin/offices/${id}`,
  },
  trucks: {
    list: (type) => `${API_SITE_MAIN}/admin/trucks/filter/${type}`,
    add: `${API_SITE_MAIN}/admin/trucks`,
    edit: (id) => `${API_SITE_MAIN}/admin/trucks/${id}`,
    getOne: (id) => `${API_SITE_MAIN}/admin/trucks/${id}`,
    delete: (id) => `${API_SITE_MAIN}/admin/trucks/${id}`,
    enabled: `${API_SITE_MAIN}/admin/trucks/enabled`,
    listUsers: (truckId) => `${API_SITE_MAIN}/admin/trucks/${truckId}/users`,
  },
  accountNumber: {
    getOne: (id) => `${API_SITE_MAIN}/admin/account-numbers/${id}`,
    edit: (id) => `${API_SITE_MAIN}/admin/account-numbers/${id}`,
    delete: (id) => `${API_SITE_MAIN}/admin/account-numbers/${id}`,
  },
  parentCity: {
    list: (countryId, typeFilter) =>
      `${API_SITE_MAIN}/admin/countries/${countryId}/parent-cities/filter/${typeFilter}`,
    getOne: (id) => `${API_SITE_MAIN}/admin/parent-cities/${id}`,
    add: `${API_SITE_MAIN}/admin/parent-cities`,
    edit: (id) => `${API_SITE_MAIN}/admin/parent-cities/${id}`,
    delete: (id) => `${API_SITE_MAIN}/admin/parent-cities/${id}`,
  },
  parentOffice: {
    list: (parentCityId) =>
      `${API_SITE_MAIN}/admin/parent-cities/${parentCityId}/parent-offices`,
    getOne: (id) => `${API_SITE_MAIN}/admin/parent-offices/${id}`,
    add: `${API_SITE_MAIN}/admin/parent-offices`,
    edit: (id) => `${API_SITE_MAIN}/admin/parent-offices/${id}`,
    delete: (id) => `${API_SITE_MAIN}/admin/parent-offices/${id}`,
  },
  commune: {
    list: (officeId, typeFilter) =>
      `${API_SITE_MAIN}/admin/offices/${officeId}/${typeFilter}/communes`,
    getOne: (id) => `${API_SITE_MAIN}/admin/communes/${id}`,
    add: `${API_SITE_MAIN}/admin/communes`,
    edit: (id) => `${API_SITE_MAIN}/admin/communes/${id}`,
    delete: (id) => `${API_SITE_MAIN}/admin/communes/${id}`,
  },

  company: {
    config: `${API_SITE_MAIN}/admin/companies/config`,
    list: `${API_SITE_MAIN}/admin/companies`,
    add: `${API_SITE_MAIN}/admin/companies`,
    getOne: (id) => `${API_SITE_MAIN}/admin/companies/${id}`,
    edit: (id) => `${API_SITE_MAIN}/admin/companies/${id}`,
    delete: (id) => `${API_SITE_MAIN}/admin/companies/${id}`,
    enabled: `${API_SITE_MAIN}/admin/companies/enabled`,
    sendEmail: (id) => `${API_SITE_MAIN}/admin/companies/verifiy-admin/${id}`,
  },
  comunaPadre: {
    list: (id) => `${API_SITE_MAIN}/admin/comuna-master/${id}`,
    add: `${API_SITE_MAIN}/admin/comuna-master`,
    edit: (id) => `${API_SITE_MAIN}/admin/comuna-master/${id}`,
    delete: (id) => `${API_SITE_MAIN}/admin/comuna-master/delete/${id}`,
  },
  oficina: {
    list: (id) => `${API_SITE_MAIN}/admin/oficinas/${id}`,
    add: `${API_SITE_MAIN}/admin/oficinas`,
    edit: (id) => `${API_SITE_MAIN}/admin/oficinas/${id}`,
    delete: (id) => `${API_SITE_MAIN}/admin/oficinas/delete/${id}`,
  },
  tax: {
    list: `${API_SITE_MAIN}/admin/taxes`,
    add: `${API_SITE_MAIN}/admin/taxes`,
  },

  depositAccount: {
    list: (typeFilter, city_id) =>
      `${API_SITE_MAIN}/admin/deposit-accounts/filter/${typeFilter}/${city_id}`,
    add: `${API_SITE_MAIN}/admin/deposit-accounts`,
    edit: (id) => `${API_SITE_MAIN}/admin/deposit-accounts/${id}`,
    getOne: (id) => `${API_SITE_MAIN}/admin/deposit-accounts/${id}`,
    delete: (id) => `${API_SITE_MAIN}/admin/deposit-accounts/${id}`,
  },
  country: {
    list: (typeFilter) =>
      `${API_SITE_MAIN}/admin/countries/filter/${typeFilter}`,
    add: `${API_SITE_MAIN}/admin/countries`,
    edit: (id) => `${API_SITE_MAIN}/admin/countries/${id}`,
    getOne: (id) => `${API_SITE_MAIN}/admin/countries/${id}`,
    delete: (id) => `${API_SITE_MAIN}/admin/countries/${id}`,
  },
  activity: {
    list: (country_id) =>
      `${API_SITE_MAIN}/admin/countries/${country_id}/activities`,
    add: `${API_SITE_MAIN}/admin/activities`,
    edit: (id) => `${API_SITE_MAIN}/admin/activities/${id}`,
    getOne: (id) => `${API_SITE_MAIN}/admin/activities/${id}`,
    delete: (id) => `${API_SITE_MAIN}/admin/activities/${id}`,
  },

  orders: {
    getData: `${API_SITE_MAIN}/transport-orders`,
    add: `${API_SITE_MAIN}/transport-orders`,
    getOne: (order_number) =>
      `${API_SITE_MAIN}/transport-orders/${order_number}`,
    incompletes: (page) =>
      `${API_SITE_MAIN}/transport-orders/incomplete?page=${page}&limit=500`,
    updateIncomplete: (order_number) =>
      `${API_SITE_MAIN}/transport-orders/${order_number}/incomplete`,
    searchGlobal: (page) =>
      `${API_SITE_MAIN}/transport-orders/search-global?page=${page}&limit=1000`,
    searchExcel: `${API_SITE_MAIN}/transport-orders/excel`,
    searchPackage: `${API_SITE_MAIN}/transport-orders/search-package`,
    receivePackage: `${API_SITE_MAIN}/transport-orders/receive-package`,
    exportPdf: `${API_SITE_MAIN}/transport-orders/pdf-export`,
    received: (page) =>
      `${API_SITE_MAIN}/transport-orders/received-office?page=${page}&limit=500`,
    getPackages: (order_number) =>
      `${API_SITE_MAIN}/transport-orders/print/${order_number}/packages`,
    update: (order_number) =>
      `${API_SITE_MAIN}/transport-orders/${order_number}`,
    cancel: `${API_SITE_MAIN}/transport-orders/cancel`,
    creditNote: `${API_SITE_MAIN}/transport-orders/credit-note`,
    assignAccountability: `${API_SITE_MAIN}/transport-orders/assign-accountability`,
    generateDoc: `${API_SITE_MAIN}/transport-orders/generate-document`,
    credit: `${API_SITE_MAIN}/transport-orders/invoice-credit`,
    payable: `${API_SITE_MAIN}/transport-orders/payment-document`,
  },
  payroll: {
    list: `${API_SITE_MAIN}/payrolls`,
    add: `${API_SITE_MAIN}/payrolls`,
    getOne: (payroll_number) => `${API_SITE_MAIN}/payrolls/${payroll_number}`,
    setDelivery: `${API_SITE_MAIN}/payrolls/home`,
    transit: `${API_SITE_MAIN}/transport-orders/receive`,
    searchGlobal: (page) =>
      `${API_SITE_MAIN}/transport-orders/filter?page=${page}&limit=1`,
  },
  home: {
    getData: `${API_SITE_MAIN}/home`,
  },
  solutions: {
    search: `${API_SITE_MAIN}/soluctions/search-order`,
    searchOffice: `${API_SITE_MAIN}/soluctions/search-office`,
    searchDelivery: `${API_SITE_MAIN}/soluctions/search-delivery`,
    updateDateCreated: `${API_SITE_MAIN}/soluctions/order-date`,
    updateDateDelivery: `${API_SITE_MAIN}/soluctions/order-delivery`,
    updateOffice: `${API_SITE_MAIN}/soluctions/order-office`,
  },
  guide: {
    initial: `${API_SITE_MAIN}/guide`,
    getOrder: (order_number) =>
      `${API_SITE_MAIN}/transport-orders/${order_number}/search`,
    add: `${API_SITE_MAIN}/guide`,
    list: `${API_SITE_MAIN}/guide/list`,
  },
  payments: {
    list: `${API_SITE_MAIN}/payments`,
  },
  delivery: {
    searchOrder: (order_number) =>
      `${API_SITE_MAIN}/transport-orders/${order_number}/search-deliver`,
    add: `${API_SITE_MAIN}/transport-orders/delivered`,
    customer: `${API_SITE_MAIN}/customers/transport-orders`,
    returnOrder: `${API_SITE_MAIN}/transport-orders/devolve`,
  },
  withdrawal: {
    list: `${API_SITE_MAIN}/withdrawals`,
    add: `${API_SITE_MAIN}/withdrawals`,
    getTicket: (number_ticket) =>
      `${API_SITE_MAIN}/withdrawals/${number_ticket}/ticket`,
    getList: (page) =>
      `${API_SITE_MAIN}/withdrawals/all?page=${page}&limit=1000`,
    assignVehicle: (page) =>
      `${API_SITE_MAIN}/withdrawals/assign-vehicle?page=${page}&limit=500`,
    assignVehicleUpdate: `${API_SITE_MAIN}/withdrawals/assign-vehicle/updated`,
    nominate: `${API_SITE_MAIN}/withdrawals/nominate`,
    search: (numero_retiro) => `${API_SITE_MAIN}/withdrawals/${numero_retiro}`,
    updateSender: (numero_retiro) =>
      `${API_SITE_MAIN}/withdrawals/${numero_retiro}/updated-sender`,
    cancel: `${API_SITE_MAIN}/withdrawals/cancel`,
    exportExcel: `${API_SITE_MAIN}/withdrawals/export-excel`,
    cancelPreorden: `${API_SITE_MAIN}/withdrawals/cancel-preorden`,
    updateAddress: (numero_retiro) =>
      `${API_SITE_MAIN}/withdrawals/${numero_retiro}/updated-preorden`,
  },
  invoice: {
    searchMonth: `${API_SITE_MAIN}/invoice/search`,
    add: `${API_SITE_MAIN}/invoice`,
    history: `${API_SITE_MAIN}/invoice/history`,
    historyOrders: `${API_SITE_MAIN}/invoice/history-orders`,
    exportPDF: `${API_SITE_MAIN}/invoice/export-pdf`,
    exportExcel: `${API_SITE_MAIN}/invoice/export-excel`,
    tributary: `${API_SITE_MAIN}/invoice/search-tributary`,
    searchCustomer: (internal_code) =>
      `${API_SITE_MAIN}/invoice/customer/${internal_code}/document`,
    payments: `${API_SITE_MAIN}/invoice/payments`,
    listPayments: (page) =>
      `${API_SITE_MAIN}/invoice/search-payments?page=${page}&limit=500`,
    download: `${API_SITE_MAIN}/invoice/download-list`,
    getDocument: (payment_id) =>
      `${API_SITE_MAIN}/invoice/payment/${payment_id}/document`,
  },
  accountability: {
    searchGlobal: `${API_SITE_MAIN}/cash-accountability/search`,
    user: `${API_SITE_MAIN}/cash-accountability/user`,
    listInitial: `${API_SITE_MAIN}/cash-accountability`,
    orders: `${API_SITE_MAIN}/cash-accountability/transport-orders`,
    add: `${API_SITE_MAIN}/cash-accountability`,
    export: `${API_SITE_MAIN}/cash-accountability/export-excel`,
    comment: (accountability_id) =>
      `${API_SITE_MAIN}/cash-accountability/${accountability_id}/comment`,
    reopen: `${API_SITE_MAIN}/cash-accountability/reopen`,
    deleteDeposit: `${API_SITE_MAIN}/cash-accountability/delete-deposit`,
    getOne: (accountability_id) =>
      `${API_SITE_MAIN}/cash-accountability/${accountability_id}`,
  },
};

export default endPoints;
