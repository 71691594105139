import {
  LIST_DATA_INITIAL_ORDEN,
  DATA_SEARCH_ORDEN,
  LIST_ORDER_INCOMPLETE,
  UPDATE_ORDEN_INCOMPLETE,
  LOADING_SEARCH_ORDERS_NOM,
  FILTERED_ORDERS,
  CLEAN_NAME_DESTINO_NOM,
  CLEAN_SELECTED_ORDEN_NOM,
  TOTAL_SELECTED_ORDEN_NOM,
  UPDATE_SELECTED_ORDEN_NOM,
  PREVIEW_PDF_ORDENES,
  SEARCH_ORDEN_RECEIVE,
  CLEAR_TOTAL_ORDEN_ENTREGA,
  UPDATE_CLIENTE_ORDENES_INPUT,
  UPDATE_INPUT_DOCUMENTO,
  UPDATE_TOTAL_MONTO,
  UPDATE_CLIENTE_ORDENES,
  SEARCH_CLIENTE_ORDENES,
  CANCEL_ORDEN,
  GENERAR_NOTA_CREDITO,
  DISABLED_GENERATE_DOCUMENT,
} from "types/transportOrdersType";
//import { ordenInitial } from "../utils/OrdenFlete/DataInitialOrden";

const INITIAL_STATE = {
  documentacionsCarga: [],
  documentacionsPago: [],
  documentacionsPagoSinB1: [],
  tipoEnvios: [],
  formaPagos: [],
  estados: [],
  estadosAll: [],
  estadoRetiros: [],
  dataTransportOrderLast: null,
  dataTransportOrder: null,
  initialOrden: true,
  visibleCarga: true,
  visibleDownload: false,
  listOrderIncomplete: [],
  isLoadingOrderIncomplete: false,
  loadingSearch: false,
  listOrden: [],
  destino_nomina: "",
  dataSearch: null,
  disabledSearch: true,
  name_excel: "",
  title_destino: {
    name_pendiente_sucursal: "",
    name_enviado: "",
  },
  totalSelectedOrden: {},
  title: false,
  name_pdf: "",
  name_pdf_orden: "",
  isLoadingReceive: false,
  infoReceive: {
    receive: 0,
    completed: 0,
    incomplete: 0,
    otherOffice: 0,
    orderToCome: 0,
  },
  listOrdensReceive: [],
  listOrdenEntregar: [],
  disabledBtnPago: true,
  totalMonto: 0,
  listEntregada: [],
  visibleMetodoPago: false,
};

const transportOrdersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LIST_DATA_INITIAL_ORDEN:
      return {
        ...state,
        documentacionsCarga: action.payload.documentacionsCarga,
        documentacionsPago: action.payload.documentacionsPago,
        tipoEnvios: action.payload.tipoEnvios,
        formaPagos: action.payload.formaPagos,
        estados: action.payload.estados,
        estadosAll: action.payload.estadosAll,
        documentacionsPagoSinB1: action.payload.documentacionsPagoSinB1,
        estadoRetiros: action.payload.estadoRetiros,
      };
    case DATA_SEARCH_ORDEN:
      return {
        ...state,
        dataTransportOrderLast: action.payload.data,
        dataTransportOrder: action.payload.data,
        initialOrden: action.payload.initial,
        visibleCarga: action.payload.visibleCarga,
        visibleDownload: action.payload.visibleDownload,
        /**disabledBtnAnular: action.payload.disabledBtnAnular,
        disabledBtnCrear: action.payload.disabledBtnCrear,
        disabledImprimir: action.payload.disabledImprimir,
        disabledTracking: action.payload.disabledTracking,
        visibleCarga: action.payload.visibleCarga,
        isBloqueCta: action.payload.isBloqueCta,**/
      };
    case LIST_ORDER_INCOMPLETE:
      return {
        ...state,
        listOrderIncomplete: action.payload.listOrderIncomplete,
        isLoadingOrderIncomplete: action.payload.isLoadingOrderIncomplete,
      };
    case UPDATE_ORDEN_INCOMPLETE:
      return {
        ...state,
        listOrderIncomplete: action.payload.listOrdens,
      };
    case LOADING_SEARCH_ORDERS_NOM:
      return {
        ...state,
        loadingSearch: true,
      };
    case FILTERED_ORDERS:
      return {
        ...state,
        listOrden: action.payload.orders,
        loadingSearch: false,
        destino_nomina: action.payload.destino_nomina,
        //name_pdf: action.payload.name_pdf,
        dataSearch: action.payload.dataSearch,
        disabledSearch: action.payload.disabledSearch,
        name_excel: action.payload.name_excel,
      };
    case CLEAN_NAME_DESTINO_NOM:
      return {
        ...state,
        title_destino: action.payload,
      };
    case CLEAN_SELECTED_ORDEN_NOM:
      return {
        ...state,
        totalSelectedOrden: action.payload.totales,
        listOrden: action.payload.listadoOrden,
        title: true,
        destino_nomina: action.payload.destino_nomina,
        name_pdf: action.payload.name_pdf,
        name_excel: action.payload.name_excel,
      };
    case TOTAL_SELECTED_ORDEN_NOM:
      return {
        ...state,
        totalSelectedOrden: action.payload,
      };
    case UPDATE_SELECTED_ORDEN_NOM:
      return {
        ...state,
        listOrden: action.payload,
      };
    case PREVIEW_PDF_ORDENES:
      return {
        ...state,
        name_pdf_orden: action.payload.pdf_name,
      };
    case SEARCH_ORDEN_RECEIVE:
      return {
        ...state,
        isLoadingReceive: action.payload.isLoadingReceive,
        listOrdensReceive: action.payload.data,
        infoReceive: action.payload.infoReceive,
      };
    case CLEAR_TOTAL_ORDEN_ENTREGA:
      return {
        ...state,
        listOrdenEntregar: action.payload.ordenes,
        disabledBtnPago: true,
        totalMonto: 0,
        listEntregada: action.payload.entregadas,
        visibleMetodoPago: action.payload.visibleMetodoPago,
      };
    case UPDATE_CLIENTE_ORDENES_INPUT:
      return {
        ...state,
        listOrdenEntregar: action.payload.updateOrden,
        visibleMetodoPago: action.payload.visibleMetodoPago,
      };
    case UPDATE_INPUT_DOCUMENTO:
      return {
        ...state,
        listOrdenEntregar: action.payload,
      };
    case UPDATE_TOTAL_MONTO:
      return {
        ...state,
        totalMonto: action.payload,
      };
    case UPDATE_CLIENTE_ORDENES:
      return {
        ...state,
        listOrdenEntregar: action.payload.ordenes,
        listEntregada: action.payload.entregadas,
        visibleMetodoPago: action.payload.visibleMetodoPago,
      };
    case SEARCH_CLIENTE_ORDENES:
      return {
        ...state,
        listOrdenEntregar: action.payload.listOrdenEntregar,
        disabledBtnPago: false,
      };
    case CANCEL_ORDEN:
      return {
        ...state,

        dataTransportOrder: {
          ...state.dataTransportOrder,
          user_name: action.payload.user_name,
          estado_orden: action.payload.estado_orden,
          listEstado: action.payload.listEstado,
        },
      };
    case GENERAR_NOTA_CREDITO:
      return {
        ...state,
        dataTransportOrder: action.payload.dataTransportOrder,
        dataTransportOrderLast: action.payload.dataTransportOrderLast,
        visibleDownload: action.payload.visibleDownload,
      };
    case DISABLED_GENERATE_DOCUMENT:
      return {
        ...state,
        dataTransportOrder: action.payload,
      };
    default:
      return state;
  }
};

export default transportOrdersReducer;
