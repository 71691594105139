//import React, { useState } from "react";
//import { Col, Row, Form } from "react-bootstrap";
import TitlePrincipal from "components/Layouts/TitlePrincipal";

//import { resetListadoOrdenes } from "actions/transportOrdersAction";
//import { useDispatch } from "react-redux";
import EntregaDirecto from "./EntregaDirecto";
//import EntregaOtraPersona from "./EntregaOtraPersona";

const OpcionesEntrega = ({ toast }) => {
  //const dispatch = useDispatch();
  //const [entrega, setEntrega] = useState(true);

  /**const bloquearCliente = (event) => {
    dispatch(resetListadoOrdenes());
    if (event.target.checked) {
      setEntrega(false);
    } else {
      setEntrega(true);
    }
  };**/

  return (
    <>
      <TitlePrincipal title="BUSCAR ENCOMIENDA" />
      <EntregaDirecto toast={toast} />
      {/**<Row>
        <Col xs={12} className="text-right contenedor-cliente bg-soft-blue">
          <Form.Group as={Col} controlId="no_aplica">
            <Form.Check
              type="checkbox"
              name="no_aplica"
              defaultValue="si"
              onChange={(event) => bloquearCliente(event)}
              //ref={register}
              label="Recibe otra Persona"
            />
          </Form.Group>
        </Col>
      </Row>
      {entrega ? (
        <EntregaDirecto toast={toast} />
      ) : (
        <EntregaOtraPersona toast={toast} />
      )} */}
    </>
  );
};

export default OpcionesEntrega;
